import { graphql } from 'gatsby';
import * as React from 'react';
import FullWidthImage from '../components/global/full-width-image/full-width-image';
import Layout from '../components/global/layout/layout';
import ContactInfo from '../components/kontakt/contact-info/contact-info';

export const pageQuery = graphql`
  query KontaktQuery {
    site {
      siteMetadata {
        titleSuffix
        titleDivider
      }
    }
    restaurant: file(name: {eq: "restaurant-3"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default props => {
    return (
        <Layout wrapperClassName="bg-pattern-gold"
            title="Kontakt"
            description="Nehmen Sie einfach Kontakt mit uns auf - per Telefon, E-Mail oder Kontaktformular."
            titleSuffix={props.data.site.siteMetadata.titleSuffix}
            titleDivider={props.data.site.siteMetadata.titleDivider}>
            <FullWidthImage tag="header" fluid={props.data.restaurant.childImageSharp.fluid}/>
            <div className="container py-5">
                <div className="row">
                    <div className="col-12 col-md-6 mb-4">
                      <p>
                        Unser wichtigstes Anliegen ist es, Ihnen - unserem Gast - einen
                        unvergesslichen Aufenthalt in der charmanten Atmosphäre des
                        Westerburger Schlosses zu bieten.
                      </p>
                      <p>
                        Wir nehmen Ihre Anfragen telefonisch oder per Mail entgegen. Gerne
                        stehen wir auch für ein persönliches Gespräch vor Ort zur Verfügung.
                      </p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
                      <h2 className="color-gold mb-5">So erreichen Sie uns</h2>
                      <ContactInfo/>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
